var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DefaultLayout", [
    _c(
      "div",
      {
        staticClass: "text-center py-48 text-white",
        style: _vm.backgroundImage,
        attrs: { id: "teaser-image" }
      },
      [
        _c("h1", { staticClass: "text-6xl" }, [
          _vm._v(_vm._s(_vm.$t("casino.title")))
        ]),
        _c("p", { staticClass: "text-4xl my-4" }, [
          _vm._v(_vm._s(_vm.$t("casino.unplanned")))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }