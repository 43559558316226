<template>
  <DefaultLayout>
    <div
      id="teaser-image"
      class="text-center py-48 text-white"
      :style="backgroundImage"
    >
      <h1 class="text-6xl">{{ $t('casino.title') }}</h1>
      <p class="text-4xl my-4">{{ $t('casino.unplanned') }}</p>
    </div>
  </DefaultLayout>
</template>

<script>
export default {
  name: 'Zelten',
  computed: {
    backgroundImage() {
      return (
        'background-image: url("' +
        require(`@/assets/images/header_casino.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
  },
};
</script>
